/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { css } from 'react-emotion';
import { Helmet } from 'react-helmet';
import { PaperBox, Flex } from '../components/Layout';
import PageWrapper from '../components/PageWrapper';
import colors from '../utils/colors';
import BackgroundImage from 'gatsby-background-image';

import moon from '../assets/images/moon.png';
import gooseberry from '../assets/images/gooseberry-and-waves.png';
import waves from '../assets/images/waves-bottom.png';

const imgStyle = css`
  border-radius: 5px;
`;

const mainName = css`
  text-align: left;
  font-size: 1.2em;
  font-weight: bold;
`;

const top = css`
  padding-top: 40px;
  text-align: center;
  @media screen and (max-width: 600px) {
    padding-top: 70px;
    }
`;

const bottom = css`
  padding-bottom: 40px;
`;

const padding = css`
  padding-top: 50px;
  @media screen and (max-width: 600px) {
      padding-top: 100px;
    }
`;

const noTop = css`
  padding-top: 0;
  margin-top: 0;
`;

const welcomeText = css`
  padding-bottom: 10px;
  padding-top: 0px;
`;

const fullWidth = css`
  width: 100%;
`;

const Gathering = ({ data }) => {
  const mapData = data.map.childImageSharp;
  return (
    <PageWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gathering - Beacons</title>
      </Helmet>

      <BackgroundImage
        Tag="section"
        className={imgStyle}
        fluid={mapData.fluid}
        backgroundColor={`#FFFFFF`}
        padding="20px"
        margin="40px"
      >
        <Flex>
          <PaperBox 
            width={[1, '80%', '60%']}
            ml="auto"
            mr="auto"
            mt="50px"
            px={[3, 3, 5]}
            py={[3, 3, 3]}
            color={colors.secondary}
            textAlign="center"
          >
            <p className={top}><img src={moon} alt="the moon" width="300"/></p>
            <h1 className={noTop}>Everyone is invited to<br/>The Gathering</h1>
            <p>Sunday 19th December 3.30pm<br/>Sunny Sands, Folkestone</p>
            <p>BRING YOUR SEA GOOSEBERRY IF YOU HAVE ONE!</p>
            <p><img src={gooseberry} alt="Sea Gooseberry and waves" className={fullWidth}/></p>
            <p>The approach of the winter solstice is a reminder of our connection to the natural world.  The full moon is when the tide and the Sea Gooseberries magic are at their strongest. We will celebrate the reuniting of the Sea Gooseberries with music, fire and feasting.</p>
            <p>THE MAGIC WILL ONLY WORK IF ALL THE SEA GOOSEBERRIES ARE BROUGHT TOGETHER.</p>
            <p>JOIN US TO CELEBRATE!</p>
            <p><img src={waves} alt="waves" className={fullWidth}/></p>
          </PaperBox>
        </Flex>
      </BackgroundImage>
    </PageWrapper>
  );
};

export const query = graphql`
query {
  map: file(relativePath: { eq: "map.jpeg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  allContentJson {
		edges {
			node {
				index {
					title
					aboutText
				}
      }
    }
  }
}
`;

export default Gathering;
